import React from 'react';
import styled from 'styled-components';
import qs from 'query-string';
import * as JStorage from 'rev.sdk.js/Actions/JStorage';
import * as AppActions from '../../AppActions';
import * as Antd from 'antd';
import {setSelectedStore} from '../../Components/SelectStoreModal';
import {
  setSelectedTable,
  unsetSelectedData,
} from '../../Components/SelectOrderTypeModal';
import * as StoreUtil from '../../Utils/StoreUtil';
import PromoAppModal, {usePromoAppModal} from '../../Components/PromoAppModal';
import {triggerEvent} from '../../Hooks/useGoogleAnalytics';

export default function TablePage(props) {
  const params = qs.parse(props.location?.search || '');
  const [store, setStore] = React.useState(null);

  usePromoAppModal();

  React.useEffect(() => {
    async function fetchData() {
      AppActions.setLoading(true);
      try {
        setStore(
          await JStorage.fetchOneDocument('store', {
            id: params.store,
          }),
        );
      } catch (ex) {
        console.warn(ex);
      }
      AppActions.setLoading(false);
    }

    if (params.store) {
      fetchData();
    }
  }, [params.store]);

  async function startToOrder() {
    try {
      // const table = parseInt(params.table, 10);
      const table = params.table;
      if (table) {
        setSelectedStore(store);
        setSelectedTable(table);
        AppActions.navigate(`/products/?showMenu=true`);
        triggerEvent('scan_qrcode', {
          order_type: 'table',
          store_name: store?.name,
          table: table,
        });
        return;
      } else {
        setSelectedStore(store);
        unsetSelectedData();
        AppActions.navigate(`/products/?showMenu=true`);
        triggerEvent('scan_qrcode', {
          order_type: 'takeaway',
          store_name: store?.name,
        });
        return;
      }
    } catch (ex) {
      console.warn(ex);
    }

    alert('錯誤的店家或桌號');
  }

  if (!store) {
    return (
      <Wrapper>
        <div className="content">
          {store === null ? <h2>載入中...</h2> : <h2>無效的店家</h2>}
        </div>
      </Wrapper>
    );
  }

  const disableImmediateOrder =
    store && !StoreUtil.isStoreOpen({store, dt: new Date()}) && !params.table;

  return (
    <Wrapper>
      <div className="content">
        <img src={'/images/logo.png'} alt="laya" />
        <h2>分店: {store.name}</h2>
        <h2 className="secondary">
          {params.table ? `桌號: ${params.table}` : '外帶立即取餐'}
        </h2>
        {disableImmediateOrder && (
          <div style={{marginTop: 40, color: 'red', textAlign: 'center'}}>
            現在非店家供應時間
          </div>
        )}
        <Antd.Button
          size={'large'}
          type="primary"
          onClick={startToOrder}
          disabled={disableImmediateOrder}
          style={{marginTop: 20}}>
          開始點餐
        </Antd.Button>
      </div>

      <PromoAppModal />
    </Wrapper>
  );
}

const Wrapper = styled.div`
  padding: 60px 20px 20px 20px;
  min-height: 100vh;
  background-color: #eee;

  & > .content {
    border-radius: 8px;
    padding: 10px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);
    margin: 0 auto;
    max-width: 720px;
    background-color: white;
    display: flex;
    flex-direction: column;
    align-items: center;

    & > h2 {
      margin: 0;

      &.secondary {
        color: #888;
      }
    }

    & > img {
      width: 200px;
      height: 200px;
      object-fit: contain;
      margin-bottom: 15px;
    }
  }
`;
